export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [0,4,5,7];

export const dictionary = {
		"/": [8],
		"/404": [9],
		"/courses/[id]": [11,[2]],
		"/courses/[id]/attendance": [12,[2]],
		"/courses/[id]/certificates": [13,[2]],
		"/courses/[id]/landingpage": [14,[2]],
		"/courses/[id]/lessons": [15,[2]],
		"/courses/[id]/lessons/[...lessonParams]": [~16,[2]],
		"/courses/[id]/marks": [17,[2]],
		"/courses/[id]/people": [18,[2,3]],
		"/courses/[id]/people/[personId]": [19,[2,3]],
		"/courses/[id]/settings": [20,[2]],
		"/courses/[id]/submissions": [21,[2]],
		"/course/[slug]": [10],
		"/forgot": [22],
		"/home": [23],
		"/invite/s/[hash]": [24,[4]],
		"/invite/t/[hash]": [25,[5]],
		"/lms": [26,[6]],
		"/lms/community": [27,[6]],
		"/lms/community/ask": [29,[6]],
		"/lms/community/[slug]": [28,[6]],
		"/lms/exercises": [30,[6]],
		"/lms/explore": [31,[6]],
		"/lms/mylearning": [32,[6]],
		"/lms/settings": [33,[6]],
		"/login": [34],
		"/logout": [35],
		"/onboarding": [36],
		"/org/[slug]": [37,[7]],
		"/org/[slug]/audience": [38,[7]],
		"/org/[slug]/audience/[...params]": [39,[7]],
		"/org/[slug]/community": [40,[7]],
		"/org/[slug]/community/ask": [42,[7]],
		"/org/[slug]/community/[slug]": [41,[7]],
		"/org/[slug]/courses": [43,[7]],
		"/org/[slug]/quiz": [44,[7]],
		"/org/[slug]/quiz/[slug]": [45,[7]],
		"/org/[slug]/settings": [46,[7]],
		"/org/[slug]/settings/customize-lms": [47,[7]],
		"/org/[slug]/settings/domains": [48,[7]],
		"/org/[slug]/settings/teams": [49,[7]],
		"/org/[slug]/setup": [50,[7]],
		"/profile/[id]": [51],
		"/reset": [52],
		"/signup": [53],
		"/upgrade": [54]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';